html {
  @apply h-full font-primary;
}
#root{
  @apply h-full flex flex-col;
}
body {
  @apply text-[0.875rem] flex flex-col h-full leading-normal;
  overscroll-behavior: none;
}
.text-padding-top{
  @apply pt-[0.15em];
}
.text-padding-top-25{
  @apply pt-[0.25em];
}
.text-padding-top-7{
  @apply pt-[0.07em];
}
*:focus{
  outline: none !important;
}
.loading-details{
  @apply h-[calc(100vh-120px)] relative w-full;
}
h1, h2,h3{
  @apply font-secondary;
}
.slick-slider{
  visibility: hidden;
}
.slick-initialized {
  visibility: visible;
}
.hk_container{
  @apply px-10 lg:px-2 sm:px-2;
}
.hk_inner_container{
  @apply px-10 lg:px-6;
}
.sliderRef .slick-initialized .slick-slide > div{
  @apply flex w-full flex-col;
}
.sliderRef .slick-initialized .slick-slide{
  @apply min-w-[150px];
}
.sliderWrapper {
  @apply max-h-[calc(100vh-(var(--stickyHeight)+62px))] gap-2.5 w-full flex xl:flex-wrap relative;
  @apply xl:max-h-none 2xl:flex-wrap 2xl:max-h-[calc(100vh-(var(--stickyHeight)+var(--thumbHeight)+80px))];
}
.slick-dots li button{
    width: 25px;
    height: 25px;
}
.slick-dots li button:before{
    @apply !text-[10px] w-full h-full;
}
input[type="checkbox"]{
  appearance: none;
  border: 1.5px solid;
  height: 16px;
  width: 16px;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance:    none;
}
input[type="checkbox"]:before{
  content: "";
  height: 35%;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-85%) rotate(-45deg) scale(0);
  border: 1.5px solid;
  border-top: transparent;
  border-right: transparent;
  position: absolute;
  transition: 0.35s ease;

}
.tabbing button:focus,
.tabbing .slick-arrow:focus,
.tabbing input[type="checkbox"]:focus,
.tabbing *:focus,
.tabbing button:focus{
  /*box-shadow: 0 0 0 1.5px rgb(0,0,0,0.2);*/
  outline: 1px dashed black !important;
}
input[type="checkbox"]:checked:before{
  transform: translate(-50%,-85%) rotate(-45deg) scale(1);
}
.stickyButton{
  @apply fixed bottom-[calc(var(--stickyHeight)+1.25rem)] md:bottom-[calc(var(--stickyHeight)+40px+0.75rem)] right-5 md:right-3;
  @apply  opacity-0 invisible pointer-events-none bg-primary w-8 h-8 rounded-full text-white flex items-center justify-center z-[4];
  transition: 0.5s ease ;
}
.stickyButton.active{
  @apply  opacity-100 visible pointer-events-auto;
}
.iconTop{
  @apply -rotate-45 inline-flex;
}
.swiper-pagination {
  @apply xl:inline-flex hidden justify-center absolute z-[1] -translate-x-2/4 mx-auto my-0 rounded-[50px] left-2/4 bottom-[15px];
}
.swiper-pagination .swiper-pagination-bullet {
  @apply relative h-3.5 w-3.5 cursor-pointer flex p-1 before:content-[""] before:h-full before:w-full before:inline-block before:m-0 before:rounded-[50%];
}
.swiper-pagination .swiper-pagination-bullet:before {
  @apply bg-black/30;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  @apply bg-black;
}
.scrollTopVisible .stickyButton{
    @apply bottom-[calc(var(--stickyHeight)+0.875rem)] duration-150 ;
}
@media screen and (max-width: 2380px) {
    .pageView{
        @apply pr-0 transition-all duration-200;
    }
    .scrollTopVisible .pageView{
        @apply pr-14;
    }
}
/*
.rc-slider-mark-text
   @apply pointer-events-none;
}*/
