.root {
  @apply h-[calc(100%-60px)] overflow-auto;
  overscroll-behavior: none;
}
.container {
  @apply max-w-site mx-auto flex items-start xl:flex-col xl:items-center;
  @apply px-6 xl:px-0;
}
.container :global .mainImageWrapper {
  height: auto;
}
.slider {
  @apply inline-block max-w-full flex-1 sticky top-0 w-[calc(100%-500px)] px-5;
  @apply xl:relative xl:w-full xl:max-w-[680px] xl:basis-full xl:p-0 md:max-w-full;
}
.description {
  @apply flex max-w-full w-[500px] basis-[500px] px-5 relative xl:w-full xl:max-w-[500px] xl:basis-full flex-col text-left;
  /*@apply xl:basis-full;*/
}
.header {
  @apply w-full pb-2.5 mb-2.5 border-b border-borderDark/15 sticky top-0 bg-grayLight pt-7 xl:pt-5 z-[1] flex justify-between gap-5 xl:gap-4;
}
.content {
}
.heading,
.heading_price{
  @apply text-2xl xl:text-xl mxl:text-lg lg:text-base font-primary font-semibold tracking-tight;
}
.footer {
  @apply w-full pt-5 border-t border-borderDark/15 bg-grayLight pb-5 md:pb-5;
  /*sticky bottom-0*/
}
.acc_wrap {
  @apply max-h-0 overflow-hidden pt-0 transition-all duration-[0.35s] ease-[ease] invisible;
}
.acc_wrap.isActive {
  @apply max-h-[100pc] overflow-visible pt-2.5 visible;
}
.product {
  /* @apply pt-2.5; */
}
.pricewrap {
  @apply flex justify-between gap-3.5;
}
.pricewrap h3{
  @apply font-primary font-semibold;
}
.primaryAction {
  @apply w-full m-0 h-[56px] xl:h-[44px] font-medium;
}
.secondaryAction {
  @apply flex flex-wrap w-full gap-5 mt-5 md:mt-4;
}
.button {
  @apply flex-1 m-0 h-[56px] xl:h-[44px] font-medium;
}
.item {
  @apply flex items-center justify-between gap-4 py-2 text-gray-500 font-light text-sm border-b-0 border-solid border-black/10 px-2 w-full;
}
.item:nth-child(odd) {
  @apply bg-white;
}
.label {
  @apply min-w-[120px] text-left pr-1 font-medium text-right;
}
.capitalizeText {
  @apply capitalize;
}
.span {
  @apply capitalize break-all text-right pl-1 font-normal;
}
.action {
  @apply bg-transparent border-0 min-w-0 m-0 p-0 text-sm inline-flex align-middle items-center text-primary h-auto font-medium;
}
.action > span {
  @apply inline-flex min-w-[34px];
}
.buttonWrap {
  @apply gap-3 flex mt-4;
}
.productItem {
  @apply pb-7;
  /*border-b border-b-borderDark/15 last:border-b-0 last:mb-0 mb-4*/
}
.total_wrap {
  @apply flex gap-5 font-semibold my-5 justify-between text-lg mxl:text-base lg:text-sm;
}
.action > svg {
  @apply transition-all duration-500;
}
.iconswap {
  @apply rotate-180;
}
.slider :global(.sliderWrapper .swiperThumb) {
  @apply bg-gridBg pt-6;
}
.root :global(.sliderWrapper .mainSlider .swiper-wrapper){
  /*@apply xl:!h-full;*/
}
.root :global(.sliderWrapper .mainSlider .swiper-slide img){
  /*@apply top-0 ;*/
  /*transform :unset;*/
}
.list {
  @apply flex flex-col gap-3 font-normal text-sm lg:text-xs text-center italic text-gray-400 whitespace-normal mt-4;
}

.spanLink{
  @apply flex items-center text-primary hover:underline;
}
.spanLink svg{
  @apply !h-[1.3em] !w-[1.3em];
}
