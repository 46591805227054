.tab{
    @apply whitespace-nowrap border-b-2 border-transparent py-2 px-1 text-sm font-medium cursor-pointer !ml-4 flex gap-1;
    @apply xs:text-[13px] xs:!ml-1.5 first:!ml-auto last:!mr-auto;
}
:global(.tabbing) .tab:focus{
    outline-offset: -2px;
}
.active{
    @apply text-black border-black;
}

.show{
    @apply block;
}

.none {
    @apply hidden;
}