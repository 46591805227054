.root {
  @apply flex flex-col w-full mx-auto flex-1 overflow-auto;
  /* max-h-full gap-5 md:gap-3*/
}
:global(.filterIsActive) .root{
  @apply md:overflow-hidden;
}
.main{
  /*@apply h-full overflow-auto;*/
  @apply flex-1 overflow-hidden flex flex-col;
}
.product_main {
  @apply flex flex-col;
  /*overflow-scroll*/
}
.product_main :global(.loading-root){
  @apply h-[400px];
}

.topbar {
  @apply bg-grayLight sticky top-0 z-[2] border-b border-borderDark/15 flex;
}

.container {
  @apply flex w-full h-full max-w-site mx-auto;
}

.topbar_flex {
  @apply px-10 pl-11 lg:px-4 lg:pl-5 w-full flex items-center justify-between text-sm h-10;
}

.topbar_count {
  @apply text-xs text-lightText;
}

.topbar_sorting {
  @apply flex gap-1 justify-center items-center text-xs text-lightText;
}

.topbar_sorting svg {
  @apply text-black !w-[1.25em] !h-[1.25em];
}

.grid_wrapper {
  @apply w-full max-w-full mx-auto max-w-site;
}

.grid_inner {
  @apply p-2.5 flex flex-wrap mx-0 md:p-1.5 justify-center;
}

.collection {
  /*@apply min-w-[330px]; flex-1 w-full*/
  @apply flex min-w-[330px] w-auto flex-wrap items-center justify-center p-2.5 flex-col max-w-full;
  @apply w-[16.66667%];
  @apply md:min-w-[50%] md:w-[50%] md:p-1.5 cursor-pointer;
}
:global(.tabbing) .collection:focus .swatches,
:global(.tabbing) .swatches:focus-within{
  @apply visible opacity-100;
}
:global(.tabbing) .collection:focus-within{
  outline: 1px dashed black !important;
}

.collection_item {
  @apply flex flex-col items-center h-full w-full bg-gridBg p-5 pt-0 px-0;
  @apply md:p-3 md:pt-0 md:px-0 relative;
}

.collection_product {
  @apply flex flex-1 flex-col justify-start items-stretch max-w-[300px] w-full;
  /*max-w-[300px]*/
}

.collection_image {
  @apply w-full overflow-hidden pt-[100%] relative;
}

.image {
  @apply w-full object-cover object-center group-hover:opacity-75 absolute top-0 left-0;
}

.content {}

.title {
  @apply mt-2 flex items-center justify-center text-base font-medium text-gray-900 font-primary;
  @apply md:text-sm;
}

.desc {
  @apply mt-1.5 text-sm text-gray-500;
  @apply md:text-xs;
}

.price {}

.swatches {
  @apply flex justify-center opacity-0 invisible transition-all duration-500;
  @apply relative z-[1];
  /*mt-3*/
}

.collection:hover .swatches {
  @apply opacity-100 visible;
}

.swatches_item {
  @apply h-[26px] w-[26px] p-[0.25rem];
  @apply md:h-[20px] md:w-[18px] md:p-[0.15rem];
}

.swatches_item img {
  @apply rounded-full border;
}

.selected img {
  @apply border-borderDark;
}

.dropdown {
  @apply relative inline-flex min-w-[142px] text-left;
}

.dropdown_header {
  @apply cursor-pointer w-full p-2.5 pr-0 lg:pr-2 sm:pr-0 justify-between relative z-0 text-black font-medium gap-2 inline-flex -outline-offset-2;
}

.dropdown_options {
  @apply absolute m-0 p-0 border top-full border-borderGray w-full opacity-0 max-h-0 invisible transition-all duration-300 -translate-y-3 min-w-[160px] right-0;
  list-style: none;
  visibility: visible;
  pointer-events: none;
}

.dropdown_options li {
  @apply cursor-pointer bg-white px-2 hover:bg-[#f0f0f0] -outline-offset-2;
}

.isOpen {
  @apply opacity-100 max-h-[300px] visible translate-y-0 overflow-auto;
  pointer-events: auto;
}

.dropdownIcon {
  @apply transition-all duration-[350ms] inline-flex align-middle relative -top-[2px];
}

.dropdownIcon.isActive {
  @apply rotate-180;
}

.liselected {
  @apply hover:bg-[#f0f0f0];
}

.liselected .optionText {
  @apply text-primary;
}

.optionText {
  @apply px-1.5 py-2 block;
}
.root :global(#filterMain){
  @apply md:sticky md:bottom-0;
}
.filterWrp{
  @apply bg-grayLight;
}
.filterWrpInner{
  @apply flex flex-wrap gap-y-[30px] px-6 lg:px-3 bg-grayLight py-5 justify-start max-w-site mx-auto;
}
.filterReset{
  @apply text-primary font-medium;
}
.absLink{
  @apply absolute inset-0 cursor-pointer z-[1];
}
.separator{
  @apply text-lightText;
}
.separator{
  @apply text-lightText;
}
.brandTitle{
  @apply m-0 w-full min-h-9 pt-[15px] font-medium text-secondary;
}
@media (pointer: coarse) {
  .swatches{
    @apply visible opacity-100;
  }
}