.root{
    @apply flex w-full max-w-full flex-col mb-7 pt-5 xl:pt-0;
}
.stickys{
    @apply w-full sticky top-0 text-left pb-2.5 z-[2] border-b after:content-[""] after:absolute after:h-full after:w-[200%] after:bg-white after:z-[-1] after:left-1/2 after:top-0 after:-translate-x-1/2;
    /* @apply xl:relative ; */ @apply xl:pt-2.5 sm:pb-0;
}
.subtitle{
    @apply mt-2.5 text-sm font-medium flex gap-4 justify-between;
    @apply sm:text-xs mt-1.5 sm:mb-1.5;
}
.desc{
    @apply mt-2.5 text-left text-default italic;
}
.title_wrap{
    @apply w-full flex items-start justify-between sm:mb-1.5;
}
.title {
    @apply text-base pr-4 font-primary font-semibold tracking-tight md:text-sm md:pr-2.5;
} 
.details{
    @apply w-full max-w-full flex flex-col;
}
.items {
    @apply w-full basis-full max-w-full mt-[1.1rem] xl:mt-4;
}
.items :global .labelRoot{
    @apply gap-y-2;
}
.price{
    @apply text-xl font-semibold md:text-base;
}
.smaller{
    font-size: 55%;
}
.primaryButton{
    @apply min-h-14 w-full mx-0 font-medium;
}
.primaryButton svg{
    @apply relative -top-[1.5px];
}
.button{
    @apply flex flex-col h-auto gap-1.5 min-h-12 m-0 p-3;
}
.wrapper :global .hk_diamond_shape_button{
    @apply flex-none w-[calc(25%-6px)] xs:w-[calc(33.3333%-6px)] min-w-0;
}
.wrapper :global .hk_ring_style_button{
    @apply flex-none w-[calc(33.3333%-6px)] xs:w-[calc(50%-6px)];
}
.swatchImg{
    @apply h-10 w-auto;
}
.swatchText{
    @apply text-sm font-medium;
}
.wrapper{
    @apply flex flex-wrap w-full gap-2;
}
.buttonWrap{
    @apply mt-6 bottom-0 bg-white w-full flex flex-col gap-y-1.5;
    /* sticky  */
}
.backButton{
    @apply !h-12 w-full mx-0;
}
.navigate{
    @apply flex gap-0.5 items-center justify-start text-sm text-secondary cursor-pointer w-auto font-medium;
}
:global .hk_ring_size_button{
    @apply min-w-0;
}
.smallChar{
    /*@apply ml-1;*/
}
.root :global .hk_metal_color .hk_metal_color_button{
    @apply flex-row gap-[0.475rem] w-[calc(33.3333%-6px)] sm:w-[calc(50%-6px)] flex-none;
}
.swatchImgColor{
    @apply h-5 w-5;
}
/*:global(.hk_ring_style) .button ,*/
:global(.hk_ring_side) .button{
    @apply flex-none w-[calc(33.3333%-6px)] xs:w-[calc(50%-4px)];
}
:global(.hk_ring_size) .button{
    @apply flex-none basis-[calc(14.28%-6.9px)] xs:basis-[calc(16.66667%-6.7px)];
}
/* :global(.hk_diamond_shapes) .button{
    @apply sm:basis-[calc(33.333333%-6px)];
} */
:global(.hk_ring_crown) .button{ 
    @apply sm:basis-[calc(50%-8px)];
}
.root :global .hk_metal_color .hk_metal_color_button img{
    @apply h-5 w-5;
}
.subtitle li {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    @apply text-lightText
}

.subtitle li:before {
    /*content: "";*/
    /*position: relative;*/
    /*width: 6px;*/
    /*height: 6px;*/
    /*background-color: #4a4a4a;*/
    /*transform: rotate(45deg);*/
}

.subtitle li:first-child:before {
    display: none;
}
:global(.hk_diamond_shapes_button > img ){
    max-height: 1.75em;
}
.error{
    @apply text-red-500 text-[13px] flex w-full mt-1.5 font-medium gap-0.5;
}
:global(.hk_ring_style_button) .swatchImg,
:global(.hk_ring_crown_button) .swatchImg,
:global(.hk_ring_side_button) .swatchImg{
    height: 3rem;
    filter: grayscale(1) saturate(1);
    /*stroke-width: 8;*/
}

@media screen and (max-width: 500px){
    .subtitle{
        @apply gap-1 flex-col;
    }
}