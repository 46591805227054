.root {
  @apply w-full flex-1 overflow-auto;
}
.container {
  @apply flex max-w-site mx-auto px-10;
  @apply xl:flex-col xl:items-center lg:px-0;
}
.leftSideContainer {
  @apply flex flex-col max-w-full sticky xl:static top-0 gap-8 basis-[calc(100%-480px)] w-[calc(100%-480px)] h-full pr-10;
  @apply xl:w-full xl:max-w-[680px] xl:basis-full xl:pr-0 md:max-w-full;
}
.navigate {
  @apply flex gap-0.5 items-center justify-start text-sm text-secondary cursor-pointer w-auto font-medium;
}

.sliderContainer :global(.sliderWrapper .swiper-wrapper .swiper-slide:not(.swiper-slide-active) > img){
  /*@apply flex;*/
  @apply md:hidden;
}
.sliderContainer :global .mainImageWrapper{
  /*remove after Demo*/
  /* height: calc(95vh - 155px); */
  /* max-height: 850px; */
  /* @apply xl:h-auto; */
}
.sliderContainer :global .sliderWrapper > .swiper{
  @apply bg-white;
}
.sliderContainer :global .mainImageWrapper img{
  padding: 0.5px;
}
.sliderContainer img {
  @apply w-full object-cover;
  /*h-full*/
}
.sliderContainer :global .slick-slide
{
  height: inherit !important;
}
.sliderContainer :global .mainSlider > .swiper-wrapper > .swiper-slide{
    max-height: 600px;
}
/*.sliderContainer :global .mainSlider > .swiper-wrapper > .swiper-slide:not(.swiper-slide-active){
    !*display: none;*!
    visibility: hidden;
    max-height:0;
    background-color: red;
}*/
.sliderContainer :global .mainSlider > .swiper-wrapper > .swiper-slide:not(.swiper-slide-active){
    @apply md:invisible md:max-h-0;
}
.sliderContainer :global .mainSlider > .swiper-wrapper > .swiper-slide > img{
  object-fit: contain;
}
:global(.safari-macos) .sliderContainer :global .mainSlider > .swiper-wrapper > .swiper-slide > img{
  @apply top-0 max-h-[600px];
  --tw-translate-y : 0;
}
.rightSideContainer {
  @apply basis-[480px] w-[480px] my-8;
  @apply xl:w-full xl:max-w-[500px] xl:basis-full lg:px-5 my-4;
}
.content {
  @apply flex flex-col;
}
.content > :global(.hk_detailsContainer > .hk_details_wrap .hk_detail){
  @apply py-2;
}
.content > :global(.hk_detailsContainer){
  @apply gap-2;
}
.buttonSection {
  @apply flex flex-col gap-2 sticky top-0 bg-white pb-5 border-b border-b-borderGray pt-2;
}
.button {
  @apply w-full !bg-secondary text-white !h-14 m-0;
}
.list {
  @apply font-normal text-sm lg:text-xs text-center italic text-gray-400 whitespace-normal;
}
.dialogBody {
  @apply p-6 border-t border-solid border-secondary/20 cursor-pointer hover:bg-tableRowHover;
}
.dialogDetail {
  @apply flex gap-4 items-center;
}
.dialogImg {
  @apply w-20 h-20 object-contain;
}
.dialogText {
  @apply flex gap-2 flex-col text-secondary;
}
.dialogTitle {
  @apply text-base font-primary font-medium;
}
.dialogParagraph {
  @apply text-sm text-gray-500;
}
.backButton{
  @apply !h-12 w-full m-0 mt-1.5;
}
.items_wrap{
  @apply mt-6;
}
.contentInner{
  /* @apply tall:sticky top-0; */
}
/*Custom Design popup*/
.dialogSetting{
  @apply text-center py-8 px-4;
}
.dialogBoxTitle{
  @apply !p-0;
}
.dialogBoxTitle > button{
  @apply absolute top-3 right-3;
}
.buttonGroup{
  @apply mt-4 flex flex-wrap justify-center gap-2;
}
.btnSetting{
  @apply font-medium border-borderDark hover:opacity-60 px-4;
}
.dialog_subtitle{
  @apply font-medium text-lg;
}
.dialog_img{
  @apply w-16 mx-auto mb-2.5;
}
.dialog_text{
  @apply mt-1.5;
}
.sliderContainer :global(.sliderIframe){
    padding-top: 580px !important;
}

.sliderContainer :global(.sliderIframe > iframe){
    position: absolute;
    left: 0px;
    width: 100%;
    height: auto;
    min-height: inherit;
    top: 50%;
    transform: translate(0%, -50%);
}
.sliderContainer :global(.sliderIframe > iframe.IGI){
    min-height: 370px;
}
@media screen and (max-width: 579px) {
    .sliderContainer :global(.sliderIframe > iframe){
        @apply w-[270px] mx-auto left-0 right-0;
    }
    .sliderContainer :global(.sliderIframe > iframe.GCAL){
        min-height: 380px;
        @apply w-[290px];
    }
    .sliderContainer :global(.sliderIframe > iframe.IGI){
        min-height: 300px;
    }
    .sliderContainer :global(.sliderIframe){
        padding-top: 100% !important;
    }
}
/*Custom Design popup close*/
