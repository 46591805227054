.root{
    @apply bg-grayLight min-h-[60px] max-h-[80px] md:min-h-[38px] z-10 mt-auto sticky bottom-0;
    @apply flex;
}
.wrapper{
    @apply flex items-center h-full justify-between max-w-site mx-auto text-left gap-5 px-4 py-3 relative z-[1];
    @apply md:gap-2 md:px-2 md:py-1.5;
}
.fluide{
    @apply border-t border-t-borderGray relative bg-grayLight max-h-[80px] w-full;
}
.arrow{
    @apply flex;
}
.content{
    @apply flex-1 flex flex-col;
}
.title {
    @apply text-lg font-semibold text-secondary font-primary;
    @apply md:text-base sm:text-sm xs:text-[13px];
}
.detailList{
    @apply flex text-secondary text-sm md:text-[13px] xs:text-xs mt-1 flex-wrap gap-x-5 gap-y-2 md:gap-y-1 leading-none;
}
.price{
    @apply ml-1 font-medium;
}
.total{
    @apply text-xl px-3 py-2 leading-normal flex items-center font-semibold md:px-2 md:py-1;
    @apply md:text-base md:py-0 sm:text-sm xs:text-[13px];
}
.total > span{
    @apply sm:pt-0;
}
.button {
    margin: 0;
}
.button svg{
    @apply transition-all duration-500;
}
.active{
    @apply relative;
}
.active svg{
    @apply rotate-180;
}
.curtain{
    @apply fixed bottom-0 left-0 h-full bg-gridBg w-full max-h-0 transition-all duration-500 overflow-hidden invisible pb-[var(--stickyHeight)];
}
.curtain :global(.sliderWrapper){
    @apply max-h-[calc(100vh-60px)] min-h-[calc(100vh-60px)] xl:min-h-[unset] sm:min-h-[380px] relative;
}
.curtain :global .sliderWrapper > .loading-root{
    @apply h-full;
}
.isOpen{
    @apply max-h-full visible pb-0;
}
@media screen and (max-width: 580px) {
    .curtain :global(.sliderWrapper){
        @apply h-full;
    }
}
